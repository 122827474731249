import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Spinner } from 'react-bootstrap';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import { LeaveRequestList } from './components/LeaveRequestList.js';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLMSData } from './thunks/lms-data/fetch-lms-data';
import { ApprovalRequestList } from './components/ApprovalRequestList';

// MSAL imports
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient.js";
import { RedirectPage } from './components/RedirectPage.js'
import auth from './components/auth/api/auth';
import { selectIsStillLoadingLMSData } from './selectors/selectLMSData';
import { Header } from './components/Header';
import { Login } from './components/auth/Login';
import { TimesheetList } from './components/TimesheetList';
import { TimesheetDocument } from './components/TimesheetDocument';


function AuthenticatedApp() {
    console.log('we are authenticated')
    const isLoggedIn = auth.isLoggedIn;
    const dispatch = useDispatch()

    function defaultPath() {
        return '/approval-requests'
    }

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchLMSData())
        }
    }, [])

    const isStillLoading = useSelector(selectIsStillLoadingLMSData())


    if (!isLoggedIn) {
        // not logged in
        return (
            <RedirectPage />
        )
    }

    if (isLoggedIn) {
        // logged in
        return (
            <div className="App layout">
                <Header />
                <Container style={{ maxWidth: '600px' }}>
                    <main className='main'>
                        <section className="content">
                            {isStillLoading &&
                                <div style={{ textAlign: 'center' }}>
                                    <Spinner variant="success" style={{ padding: 28, margin: 56 }} />
                                </div>
                            }
                            {!isStillLoading &&
                                <Routes>
                                    <Route path='/timesheets' element={<TimesheetList />} />
                                    <Route path="/timesheets/:TimesheetNo" element={<TimesheetDocument />} />
                                    <Route path="/leave-requests" element={<LeaveRequestList />} />
                                    <Route path="/approval-requests" element={<ApprovalRequestList />} />
                                    <Route path="/approval-requests/t/:TimesheetNo" element={<TimesheetDocument />} />
                                    <Route path="*" element={<Navigate to={defaultPath()} />} />
                                </Routes>
                            }
                        </section>
                    </main>
                </Container>
            </div>
        )
    }

}

function LoginApp() {
    return (
        <Container style={{ maxWidth: '600px' }}>
            <main>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </main>
        </Container>
    )
}


function App({ pca }) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);



    return (
        <MsalProvider instance={pca}>
            <div className="App layout">

                <AuthenticatedTemplate>
                    <AuthenticatedApp />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <LoginApp />
                </UnauthenticatedTemplate>
            </div>
        </MsalProvider>
    );

}



export default App;
