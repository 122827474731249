import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faPrint, faRemove, faTrash } from '@fortawesome/free-solid-svg-icons'
import { deleter, poster } from '../thunks/thunk-utils'
import { fetchLMSData } from '../thunks/lms-data/fetch-lms-data'
import { downloadLeaveRequestReport, errorMessageFor, showError, swalFire } from './utils'
import { ApprovalComment, ApprovalEntries, LeaveDetails } from './ApprovalRequestDialog'
import { LeaveRequestStatusBadge } from './Badges'

/**
 * @param {LeaveRequestDialogProps} props
 * @returns
 */
export function LeaveRequestDialog(props) {
    const { show, setShow, leaveRequest } = props
    
    const [showDeleteLeaveRequest, setShowDeleteLeaveRequest] = useState(false)

    const dispatch = useDispatch()
    const handleClose = () => setShow(false)
    const [showRejectionDialog, setShowRejectionDialog] = useState(false)

    async function handlePrint() {
        const documentNo = leaveRequest?.DocumentNo
        if (documentNo) {
            downloadLeaveRequestReport(documentNo)
        }
    }

    /**
     * @param {(b: boolean)=>void} inProgressSetter
     * @param {()=>Promise<void>} fn 
     */
    async function actionHandler(inProgressSetter, fn) {
        try {
            inProgressSetter(true)
            await fn()
            await dispatch(fetchLMSData())
            handleClose()
        }
        catch (e) {
            console.error(e)
        }
        finally {
            inProgressSetter(false)
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>

            {/* <RejectionDialog show={showRejectionDialog} setShow={setShowRejectionDialog} approvalRequest={approvalRequest} /> */}
            <DeleteLeaveRequestDialog show={showDeleteLeaveRequest} setShow={setShowDeleteLeaveRequest} leaveRequest={props.leaveRequest} />
            
            <Modal.Header closeButton>
                <Modal.Title>Leave Request - {leaveRequest.DocumentNo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
          
                <LeaveRequestStatusBadge leaveRequest={leaveRequest} />
                <LeaveDetails leaveRequest={leaveRequest} />
                <ApprovalEntries approvalRequest={leaveRequest} />
                <ApprovalComment approvalRequest={leaveRequest} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{setShowDeleteLeaveRequest(true)}}>
                    <FontAwesomeIcon icon={faRemove} />
                    &nbsp;
                    Delete
                </Button>
                <Button variant="primary" onClick={handlePrint} className='outline'>
                    <FontAwesomeIcon icon={faPrint} />
                    &nbsp;
                    Print
                </Button >
            </Modal.Footer>
        </Modal>
    )
}

/**
 * @param {ApprovalRequestDialogProps} props
 * @returns
 */
export function RejectionDialog(props) {
    const { show, setShow, approvalRequest } = props
    const dispatch = useDispatch()
    const handleClose = () => {
        setForm({ Comment: '' })
        setShow(false)
    }
    const [isRejecting, setIsRejecting] = useState(false)

    const [form, setForm] = useState({
        Comment: '',
    })
    const handleFormChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }


    async function handleReject() {
        await actionHandler(setIsRejecting, async () => {
            if (!form.Comment) {
                await showError('Please provide rejection comment')
                return;
            }
            const resp = await poster('/approval-requests/reject', {
                requestId: approvalRequest.DocumentNo,
                comment: form.Comment
            })
            await swalFire(resp, 'Successfully rejected approval request')
        })
    }

    /**
    * @param {(b: boolean)=>void} inProgressSetter
    * @param {()=>Promise<void>} fn 
    */
    async function actionHandler(inProgressSetter, fn) {
        try {
            inProgressSetter(true)
            await fn()
            await dispatch(fetchLMSData())
            handleClose()
        }
        finally {
            inProgressSetter(false)
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Rejecting Approval Request - {approvalRequest?.DocumentNo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Rejection Comment</Form.Label>
                    <Form.Control type='text' name="Comment" onChange={handleFormChange} value={form.Comment} autoFocus={true} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleReject} disabled={isRejecting} className='outline'>
                    <FontAwesomeIcon icon={faCancel} />
                    &nbsp;
                    {isRejecting ? 'Rejecting' : 'Reject'}
                </Button >
            </Modal.Footer>
        </Modal>
    )
}




/**
 * @param {LeaveRequestDialogProps} props
 * @returns
 */
export function DeleteLeaveRequestDialog(props) {
    const { show, setShow, leaveRequest } = props
    const dispatch = useDispatch()
    const handleClose = () => {
        setShow(false)
    }
    const [isDeleting, setIsDeleting] = useState(false)

    async function handleDelete() {
        await actionHandler(setIsDeleting, async () => {
            const resp = await deleter('/leave-requests', {
                documentNo: leaveRequest.DocumentNo
            })
            await swalFire(resp, 'Successfully deleted leave request')
        })
    }

    /**
     * @param {(b: boolean)=>void} inProgressSetter
     * @param {()=>Promise<void>} fn 
     */
    async function actionHandler(inProgressSetter, fn) {
        try {
            inProgressSetter(true)
            await fn()
            await dispatch(fetchLMSData())
            handleClose()
        }
        catch (e) {
            console.error(e)
        }
        finally {
            inProgressSetter(false)
        }
    }


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Timesheet Line {leaveRequest.DocumentNo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete the leave request?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete} disabled={isDeleting} className='outline'>
                    <FontAwesomeIcon icon={faRemove} />
                    &nbsp;
                    {isDeleting ? 'Deleting' : 'Delete'}
                </Button >
            </Modal.Footer>
        </Modal>
    )
}

