import React, { useState } from 'react'
import { SectionHeading } from '../SectionHeading.js'

import { Button, Container, Image, Stack } from 'react-bootstrap'
import { appTitle } from '../utils.js'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../authConfig.js'


export const Login = () => {
    const { instance } = useMsal();

    const handleLogin = (e) => {
        // preventing default is very essential! If left out, login will fail
        e.preventDefault() 
        instance.loginRedirect({
            ...loginRequest,
            redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI, // e.g. /redirect
        });
    }


    return (
        <Container style={{ paddingTop: 20 }}>
            <Stack style={{ textAlign: 'center' }}>
                <div>
                    <Image
                        src="/logo.png"
                        width={50}
                    />
                    <SectionHeading heading={appTitle()} />
                    <SectionHeading heading="Login" />
                </div>
            </Stack>

            <form>                
                <Button
                    variant='success'
                    type="submit"
                    style={{ width: '100%', marginTop: 20 }}
                    onClick={handleLogin}
                >
                    Login
                </Button>
            </form>
        </Container>
    )
}
