import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Stack } from "react-bootstrap";
import './css/RequestList.scss'
import { useSelector } from "react-redux";
import { selectTimesheets } from "../selectors/selectLMSData";
import { TimesheetStatusBadge } from "./Badges";
import { useNavigate } from "react-router";
// import { CreateTimesheetForm } from "./CreateTimesheetForm";
// import { TimesheetDialog } from "./TimesheetDialog";
// import { TimesheetStatusBadge } from "./TimesheetStatusBadge";

export const TimesheetList = () => {
    // const 
    /** @type {Timesheet[]} */
    const timesheets = useSelector(selectTimesheets());
    const [showCreateTimesheetForm, setShowCreateTimesheetForm] = useState(false)


    function renderTimesheets() {
        return timesheets.map(ts => {
            return (
                <TimesheetListItem timesheet={ts}
                    key={ts.No} />
            )
        })
    }
    return (
        <div>
            <h1>Timesheets</h1>
            <div className="request-list">
                {renderTimesheets()}
            </div>
        </div>

    )
}


/**
 * 
 * @param {{timesheet: Timesheet }} props 
 */
const TimesheetListItem = props => {
    const { timesheet } = props
    const [showTimesheetDialog, setShowTimesheetDialog] = useState(false)
    const navigate = useNavigate()

    function handleNavigateToDetail() {
        navigate(timesheet.No)
    }

    return (
        <div>
            <Card style={{ marginTop: 10 }} className="request-list-item">
                <div className="list-item-content">
                    <div>
                        {timesheet.No}
                        &nbsp;
                        <TimesheetStatusBadge timesheet={timesheet} />
                    </div>
                    <div>{timesheet.PeriodDescription}</div>
                </div>
                <div className="list-item-actions">
                    <Button className="edit-icon" variant="" onClick={handleNavigateToDetail}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </div>
            </Card>
        </div>
    )
}