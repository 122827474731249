import React, { useState } from 'react'
// import './css/List.scss'
// import AddIcon from '@mui/icons-material/Add';
// import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router'

import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { poster } from '../thunks/thunk-utils'
import { fetchLMSData } from '../thunks/lms-data/fetch-lms-data'
import { selectLeaveTypes } from '../selectors/selectLMSData'

/**
 * @param {DialogProps} props
 * @returns
 */
export function CreateLeaveRequestForm(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const show = props.show
    const setShow = props.setShow
    const handleClose = () => setShow(false)
    const [form, setForm] = useState({
        LeaveType: '',
        StartDate: new Date().toISOString().split('T')[0],
        EndDate: new Date().toISOString().split('T')[0]
    })
    const [isCreating, setIsCreating] = useState(false)

    async function handleSubmit() {
        try {
            setIsCreating(true)
            console.log('submitting form', form)
            if (!form.LeaveType) {
                throw new Error('Please specify LeaveType')
            }
            await poster('/leave-requests', form)
            await dispatch(fetchLMSData())
            handleClose()
        }
        catch (e) {
            console.error(e)
        }
        finally {
            setIsCreating(false)
        }
    }

    const handleFormChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }



    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create Leave Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Leave Type</Form.Label>
                        <Form.Select name="LeaveType" onChange={handleFormChange} value={form.LeaveType}>
                            <LeaveTypeOptions />
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type='date' name="StartDate" onChange={handleFormChange} value={form.StartDate} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control type='date' name="EndDate" onChange={handleFormChange} value={form.EndDate} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit} disabled={isCreating}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                    &nbsp;
                    {isCreating ? 'SUBMITTING' : 'SUBMIT'}
                </Button >
            </Modal.Footer>
        </Modal>
    )
}

export const LeaveTypeOptions = () => {
    const leaveTypes = useSelector(selectLeaveTypes())
    return (
        <>
            <option value=""></option>
            {leaveTypes.map(leaveType => {
                return (
                    <option
                        value={leaveType.Code}
                        key={leaveType.Code}
                    >
                        {leaveType.Code}
                    </option>
                )
            }
            )
            }
        </>
    )
}
