import React, { useEffect } from "react";
import { login } from "./auth/api/auth";
import Swal from "sweetalert2";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";
import { errorMessageFor } from "./utils";

export function RedirectPage() {
    const { instance } = useMsal()
    const account = instance.getActiveAccount()

    useEffect(() => {
        (async function () {
            try {
                if (!account) {
                    console.log('no account')
                    return;
                }
                if (!account?.username) {
                    console.error('no account username')
                    return
                }
                await login({ email: account.username })
            }
            catch (e) {
                const errorMsg = errorMessageFor(e)
                await Swal.fire({
                    title: 'Login Failed',
                    text: errorMsg,
                    icon: 'error'
                })
                // checkError({ error: e, payload: errorMsg })
                // logout()
                localStorage.removeItem('authToken')
                instance.logoutRedirect({
                    account: instance.getActiveAccount(),
                    onRedirectNavigate: () => !BrowserUtils.isInIframe()
                })
            }
        })()
    }, [account])

    return (
        <div>
            Loading....
        </div>
    )
}