import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Badge, Button, Card, Stack } from "react-bootstrap";
// import './css/ApprovalRequestList.scss'
import { useSelector } from "react-redux";
import { selectApprovalRequests } from "../selectors/selectLMSData";
import { ApprovalRequestDialog } from "./ApprovalRequestDialog";
import { leaveRequestDays } from "./LeaveRequestList";
import { LeaveRequestStatusBadge, badgeColorFor } from "./Badges";
import { useNavigate } from "react-router";
import _ from "lodash";
// import { CreateApprovalRequestForm } from "./CreateApprovalRequestForm";


export const ApprovalRequestList = () => {
    // const 
    /** @type {ApprovalEntry[]} */
    const approvalRequests = useSelector(selectApprovalRequests());


    function renderApprovalRequests() {
        return approvalRequests.map(approvalRequest => {
            return (
                <ApprovalRequestListItem
                    approvalRequest={approvalRequest}
                    key={approvalRequest.RequestId}
                />
            )
        })
    }
    return (
        <div>
            <h1>Approval Requests</h1>
            <div className="request-list">
                {renderApprovalRequests()}
            </div>
        </div>

    )
}


/**
 * 
 * @param {{approvalRequest: ApprovalEntry }} props 
 */
const ApprovalRequestListItem = props => {
    const { approvalRequest } = props
    const [showApprovalRequestDialog, setShowApprovalRequestDialog] = useState(false)
    const navigate = useNavigate()

    function handleOpenApprovalRequest() {
        switch (approvalRequest.DocumentType) {
            case 'Leave': {
                setShowApprovalRequestDialog(true)
                break;
            }
            case 'Timesheet': {
                navigate(`/approval-requests/t/${approvalRequest.RequestId}`)
                break;
            }
        }
    }

    return (
        <div>
            {approvalRequest.DocumentType === 'Leave' &&
                <ApprovalRequestDialog
                    setShow={setShowApprovalRequestDialog}
                    show={showApprovalRequestDialog}
                    approvalRequest={approvalRequest?.leaveRequest} />
            }

            <Card style={{ marginTop: 10 }} className="request-list-item">
                <div className="list-item-content">
                    <div>{_.capitalize(approvalRequest?.leaveRequest?.LeaveType)} {approvalRequest?.timesheet?.PeriodDescription} {approvalRequest.DocumentType}
                        &nbsp;
                        <Badge bg={badgeColorFor(approvalRequest.Status)}>
                            {approvalRequest.Status}
                        </Badge>
                    </div>
                    <div>For {approvalRequest?.leaveRequest?.EmployeeName} {approvalRequest?.timesheet?.EmployeeName}</div>
                </div>
                <div className="list-item-actions">
                    <Button className="edit-icon" variant="" onClick={handleOpenApprovalRequest}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </div>
            </Card>
        </div>
    )
}