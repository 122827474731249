import { lmsDataSaved } from '../../slices/lmsDataSlice'
import { fetcher, navThunk } from '../thunk-utils'

export const fetchLMSData = navThunk(
    'lms-data/fetchLMSData',
    async function fetch(payload, thunkAPI) {
        // query again this time including staff
        const lmsData = await fetcher('/lms-data')
        thunkAPI.dispatch(lmsDataSaved({ lmsData }))
    }
)




