import React, { useState } from 'react'
import './css/Header.scss'
import auth from './auth/api/auth'
import { Button, Container, Image, Nav, Navbar } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Avatar, Drawer, List, ListItem, ListItemButton } from '@mui/material'
import { appTitle, isLargeDevice, isSmallDevice } from './utils'
import { useMsal } from '@azure/msal-react'
import { BrowserUtils, InteractionType } from '@azure/msal-browser'
import { Client } from '@microsoft/microsoft-graph-client'
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { msalInstance } from '..'
import { useEffect } from 'react'
import { callMsGraph, getGraphClient } from '../utils/MsGraphApiCall'



export function Header(props) {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const user = auth.appuser
    const { instance } = useMsal()

    /**
     * @type {[MsGraphUserDetails,(v: MsGraphUserDetails)=>void]}
     */
    const [userDetails, setUserDetails] = useState(null)

    const [profilePhotoUrl, setProfilePhotoUrl] = useState(null)

    useEffect(() => {
        (async () => {
        })()
    }, [])

    // const userDetails = getGraphClient()
    console.log(userDetails)
    const drawerVariant = isSmallDevice() ? 'temporary' : 'permanent'

    function showApprovalRequests() {
        return true
    }

    function toggleDrawer() {
        setDrawerOpen(!drawerOpen)
    }

    function goTo(pathname) {
        navigate(pathname)
        toggleDrawer()
    }

    function isSelectedFor(requiredPathname) {
        return location.pathname.includes(requiredPathname)
    }

    function handleLogout() {
        localStorage.removeItem('authToken')
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
            onRedirectNavigate: () => !BrowserUtils.isInIframe()
        })
    }

    return (
        <header className="header">
            <Container style={{ maxWidth: '600px' }}>
                <Navbar>
                    <Container style={{ padding: 0 }}>
                        <Navbar.Brand>
                            <Image src={'/logo.png'} height={28} style={{ marginRight: 20 }} />
                            <b>{appTitle()}</b>
                        </Navbar.Brand>
                        <Nav>
                            <Nav.Item>
                                <span>{userDetails?.displayName}</span>
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <Button

                                variant=""
                                className="justify-content-end"
                                onClick={toggleDrawer}
                            >
                                <FontAwesomeIcon
                                    icon={faBars}
                                    size='lg'

                                    color={isLargeDevice() ? 'gray' : 'green'}
                                />
                            </Button>
                        </Nav>
                    </Container>
                </Navbar>
            </Container>
            <hr className='header-hr' />

            <Drawer
                anchor='left'
                open={drawerOpen}
                onClose={toggleDrawer}
                className='header-drawer'
                variant={drawerVariant}
            >
                <List style={{ textAlign: 'center' }} dense={true}>
                    <div style={{ padding: 16, textAlign: 'center', minWidth: 240 }}>
                        <Image
                            src={profilePhotoUrl}
                            roundedCircle
                            height={100}
                            style={{ marginBottom: 16 }}
                        />
                        <div>{auth.appuser?.No}</div>
                        <div>{auth.fullName}</div>
                        <div>{auth.appuser?.Job_Title}</div>
                        <div>{auth.appuser?.E_Mail}</div>
                    </div>
                    <ListItem>
                        <ListItemButton
                            selected={isSelectedFor('/timesheets')}
                            onClick={() => goTo('/timesheets')}>
                            Timesheets
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            selected={isSelectedFor('/leave-requests')}
                            onClick={() => goTo('/leave-requests')}>
                            Leave Requests
                        </ListItemButton>
                    </ListItem>
                    <ListItem hidden={!showApprovalRequests()}>
                        <ListItemButton
                            selected={isSelectedFor('/approval-requests')}
                            onClick={() => goTo('/approval-requests')}>
                            Approval Requests
                        </ListItemButton>
                    </ListItem>
                </List>
                <div></div>
                <Button
                    onClick={handleLogout}
                    variant="danger"
                    className='logout-button'>
                    Logout
                </Button>
            </Drawer>
        </header >
    )
}

