import axios from 'axios'
import { format, isAfter, isBefore } from 'date-fns'
import auth from './auth/api/auth'
import Swal from 'sweetalert2'

export async function swalFire(response, successMessage) {
    if (checkError(response)) {
        return Swal.fire({
            title: 'Success',
            text: successMessage,
            icon: 'success'
        })
    }
}


/**
 * Check for error in thunk response. Return true if there is NO error, false if THERE IS an error. If there is an error display an error dialog message. This function is used by swalFire
 * @param {string} message
 * @returns {Promise<boolean>} - true if there is NO error, false if there is an error
 */
export async function showError(message) {
    await Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error'
    })
    return false
}


/**
 * Check for error in thunk response. Return true if there is NO error, false if THERE IS an error. If there is an error display an error dialog message. This function is used by swalFire
 * @param {{ error, payload: string }} response
 * @returns {boolean} - true if there is NO error, false if there is an error
 */
export function checkError(response) {
    if (!response?.error) return true
    Swal.fire({
        title: 'Failed',
        text: response.payload,
        icon: 'error'
    })
    return false
}

export const api = process.env.REACT_APP_API

export function urlFor(relativeUrl) {
    return `${process.env.REACT_APP_API}${relativeUrl}`
}

export function bearerToken() {
    return `Bearer ${auth.authToken}`
}
/**
 * 
 * @param {string} relativeUrl 
 * @returns 
 */
export async function fetcher(relativeUrl) {
    try {
        const result = await axios({
            url: urlFor(relativeUrl),
            headers: {
                Authorization: bearerToken()
            }
        })
        return result.data
    }
    catch (e) {
        const msg = errorMessageFor(e)
        await showError(msg)
        console.error(e)
    }
}

/**
 * @param {string} documentNo 
 */
export async function downloadLeaveRequestReport(documentNo) {
    try {
        const response = await fetch(urlFor(`/reports/leave-request-report?documentNo=${documentNo}`), {
            headers: { authorization: `Bearer ${auth.authToken}` }
        })
        if (!response.ok) {
            const json = await response.json()
            throw new Error(json.error || json)
        }
        const file = await response.blob()
        const fileUrl = URL.createObjectURL(file)
        const filename = getFilenameFromHeaders(response) || `Leave Request Report - ${documentNo}.pdf`;
        downloadThroughAnchorLink(fileUrl, filename);
    } catch (e) {
        showError(errorMessageFor(e))
    }
}

/**
 * @param {string} timesheetNo 
 */
export async function downloadOpenTimesheetReport(timesheetNo) {
    try {
        const response = await fetch(urlFor(`/reports/open-timesheet-report?timesheetNo=${timesheetNo}`), {
            headers: { authorization: `Bearer ${auth.authToken}` }
        })
        if (!response.ok) {
            const json = await response.json()
            throw new Error(json.error || json)
        }
        const file = await response.blob()
        console.log({ file })
        const fileUrl = URL.createObjectURL(file)
        const filename = getFilenameFromHeaders(response) || `Open Timesheet Report - ${timesheetNo}.pdf`;
        downloadThroughAnchorLink(fileUrl, filename);
    } catch (e) {
        showError(errorMessageFor(e))
    }
}

/**
 * // note: Not working. Fails to give filename
 * @param {Response} response 
 */
function getFilenameFromHeaders(response) {
    const contentDisposition = response.headers.get('Content-Disposition')

    if (!contentDisposition) {
        return null;
    }

    let startIdx = contentDisposition.indexOf('"') + 1
    let endIdx = contentDisposition.lastIndexOf('"')
    const filename = contentDisposition.substring(startIdx, endIdx)
    return filename;
}
export function appTitle() {
    const TITLE = process.env.REACT_APP_TITLE || 'NO TITLE IN .env'
    return TITLE
}

export function deviceWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
}

export function isSmallDevice() {
    return deviceWidth() < 1000
}

export function isLargeDevice() {
    return !isSmallDevice()
}



/**
 * @param {string} datetime
 */
export function dateOnly(datetime) {
    return format(new Date(datetime), 'yyyy-MM-dd')
}

/**
 * @param {string} downloadUrl
 * @param {string} fileName
 * @returns
 */
export function downloadThroughAnchorLink(downloadUrl, fileName) {
    try {
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = fileName ?? 'myfile.txt'
        a.click()
    } catch (error) {
        return Swal.fire({
            title: 'File Download failed',
            text: 'Failed to download the file attachment. Error: ' + error.response.statusText,
            icon: 'error'
        })
    }
}


/**
* Returns true if user is logged in
* @returns {boolean}
*/
export const isLoggedIn = () => {
    return !!localStorage.getItem('authToken')
}
// beacon scholarship

/**
 *
 * @param {*} e
 * @returns {string} error message
 */
export const errorMessageFor = e => {
    console.log(e)
    return e.response?.data?.error || e.message
}
