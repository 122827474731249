import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
// import auth from "../components/auth/api/auth";
import { errorMessage, messageCleared } from '../slices/messageSlice'
import auth from '../components/auth/api/auth'
import { errorMessageFor, showError } from '../components/utils'

export const errorContent = (e) => {
    return e.response?.data?.error || e?.response?.statusText || e.message
}

/**
 *  Reusable function for calling redux thunks
 * @param {NavThunkFn} fn
 * @returns {any}
 **/
export function navThunk(thunkName, fn) {
    const thunk = createAsyncThunk(
        thunkName,
        /**
             *
             * @param {*} payload
             * @param {import("@reduxjs/toolkit/dist/createAsyncThunk").BaseThunkAPI<any,any,any>} thunkAPI
             * @returns
             */
        async function (payload, thunkAPI) {
            try {
                const result = await fn(payload, thunkAPI)
                thunkAPI.dispatch(messageCleared({}))
                return result
            } catch (e) {
                console.log('error is ', e)
                const errorMsg = errorMessageFor(e)
                await showError(errorMsg)
                const error = { content: errorContent(e) }
                thunkAPI.dispatch(errorMessage(error))
                return thunkAPI.rejectWithValue(error.content)
            }
        }
    )
    return thunk
}

/**
 *
 * @param {any} a
 * @returns any[]
 */
export function requireList(a) {
    if (!a) {
        a = { _BLANK: '' }
    }
    if (!Array.isArray(a)) {
        a = [a]
    }
    return a
}

// Get ETag for any kind of NAV record
export function getETag(rec) {
    // Below is used for old Odata versions in earlier NAV eg NAV 2015
    if (!rec.ETag) {
        throw Error('Etag can not be blank when updating')
    }
    return `W/"'${rec.ETag}'"`

    // Below is used for higher versions of NAV eg BC140
    // return rec['@odata.etag']
}

// Get a string representation of a date to send to NAV
// The returned string is in ISO format and looks like this: "2021-09-08"
export function getDateString(date) {
    let iso = date.toISOString()
    iso = iso.substring(0, iso.indexOf('T'))
    iso = iso + 'T00:00:00.000Z'
    return iso
}

export function isoDateStringWithoutTimeFor(dateOrTimestamp) {
    let dt
    if (dateOrTimestamp instanceof Date) {
        dt = dateOrTimestamp
    } else {
        dt = new Date(dateOrTimestamp)
    }
    let iso = dt.toISOString()
    iso = iso.substring(0, iso.indexOf('T'))
    return iso
}

export const api = process.env.REACT_APP_API

export function urlFor(relativeUrl) {
    return `${api}${relativeUrl}`
}

export function bearerToken() {
    return `Bearer ${auth.authToken}`
}

export async function fetcher(relativeUrl) {
    const result = await axios({
        url: urlFor(relativeUrl),
        headers: {
            Authorization: bearerToken()
        }
    })
    return result.data
}

/**
 *
 * @param {string} relativeUrl
 * @param {Record<string,any>} data
 * @returns
 */
export async function poster(relativeUrl, data) {
    try {
        const result = await axios({
            method: 'post',
            url: urlFor(relativeUrl),
            headers: {
                Authorization: bearerToken()
            },
            data
        })
        return result.data
    }
    catch(e) {
        await showError(errorMessageFor(e))
       throw e 
    }
}

/**
 *
 * @param {string} relativeUrl
 * @param {Record<string,any>} data
 * @returns
 */
export async function deleter(relativeUrl, data) {
    const result = await axios({
        method: 'delete',
        url: urlFor(relativeUrl),
        headers: {
            Authorization: bearerToken()
        },
        data
    })
    return result.data
}
