import { useNavigate, useParams } from "react-router"
import invariant from "tiny-invariant"
import { downloadOpenTimesheetReport, showError, swalFire } from "./utils"
import { SectionHeading } from "./SectionHeading"
import { useDispatch, useSelector } from "react-redux"
import { selectTimesheet } from "../selectors/selectLMSData"
import './css/Document.scss'
import { Button, Stack } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEdit, faPaperPlane, faPlus, faPrint } from "@fortawesome/free-solid-svg-icons"
import { TimesheetLineForm } from "./TimesheetLineForm"
import { useState } from "react"
import { poster } from "../thunks/thunk-utils"
import { fetchLMSData } from "../thunks/lms-data/fetch-lms-data"
import auth from "./auth/api/auth"

export function TimesheetDocument() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    try {
        invariant(params.TimesheetNo, 'params.TimesheetNo can not be blank')
    }
    catch (e) {
        showError(e.message)
    }
    const timesheetNo = params.TimesheetNo
    const timesheet = useSelector(selectTimesheet(timesheetNo))
    if (!timesheet) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    async function submitTimesheet() {
        try {
            const resp = await poster('/timesheets/submit-timesheet', timesheet)
            swalFire(resp, 'Successfully submitted timesheet')
            await dispatch(fetchLMSData())
        } catch (e) {
            console.error(e)
        }
    }
    async function approveTimesheet() {
        try {
            const resp = await poster('/approval-requests/approve', {
                requestId: timesheetNo
            })
            swalFire(resp, 'Successfully approved timesheet')
            dispatch(fetchLMSData())
            navigate('/approval-requests')
        }
        catch (e) {
            console.error(e)
        }
    }
    async function rejectTimesheet() {
        try {
            const resp = await poster('/approval-requests/reject', {
                requestId: timesheetNo,
                comment: 'Rejected'
            })
            swalFire(resp, 'Successfully rejected timesheet')
            dispatch(fetchLMSData())
            navigate('/approval-requests')
        }
        catch (e) {
            console.error(e)
        }
    }

    async function reopenTimesheet() {
        try {
            const resp = await poster('/timesheets/reopen-timesheet', timesheet)
            swalFire(resp, 'Successfully reopened timesheet')
            await dispatch(fetchLMSData())
        }
        catch (e) {
            console.error(e)
        }
    }

    async function handlePrint() {
        downloadOpenTimesheetReport(timesheetNo)
    }

    return (
        <div>
            <SectionHeading heading={`Timesheet - ${timesheetNo}`} />

            <section className='actions my-2'>
                <Stack gap={2} direction='horizontal'>
                    <Button variant="primary" onClick={handlePrint} className='outline'>
                        <FontAwesomeIcon icon={faPrint} />
                        &nbsp;
                        Print
                    </Button>
                    {(timesheet?.Status === 'Created' || timesheet?.Status === 'Rejected') &&
                        <Button variant="success" onClick={submitTimesheet} >
                            <FontAwesomeIcon icon={faPaperPlane} />
                            &nbsp;
                            Submit
                        </Button>
                    }
                    {(timesheet?.Status === 'Pending Approval' && timesheet?.EmployeeNo !== auth.appuser.No) &&
                        <>
                            <Button variant="success" onClick={approveTimesheet}>
                                <FontAwesomeIcon icon={faCheck} />
                                &nbsp;
                                Approve
                            </Button>
                            <Button variant="secondary" onClick={rejectTimesheet}>
                                <FontAwesomeIcon icon={faCheck} />
                                &nbsp;
                                Reject
                            </Button>
                        </>
                    }
                    {(timesheet?.Status !== 'Created') && timesheet?.EmployeeNo == auth.appuser.No &&
                        <Button variant="success" onClick={reopenTimesheet} >
                            <FontAwesomeIcon icon={faPaperPlane} />
                            &nbsp;
                            Reopen
                        </Button>
                    }
                </Stack>
            </section>

            <section className="header">
                <section>
                    <label>Period</label>
                    <input value={timesheet?.PeriodDescription} readOnly={true} />
                </section>
                <section>
                    <label>Employee</label>
                    <input value={timesheet.EmployeeName} readOnly={true} />
                </section>
                <section>
                    <label>Status</label>
                    <input value={timesheet.Status} readOnly={true} />
                </section>
            </section>
            <TimesheetLines timesheet={timesheet} />
        </div>
    )
}

/**
 * @param {{timesheet: Timesheet}} params0
 */
function TimesheetLines({ timesheet }) {
    const [showCreateLineForm, setShowCreateLineForm] = useState(false)
    function renderLines() {
        if (!timesheet?.lines?.length) {
            return null;
        }
        return timesheet.lines
            .filter(l => !!l.DocumentNo)
            .map(line => <TimesheetLineItem timesheetLine={line} timesheet={timesheet} key={line.LineNo} />)
    }

    return (
        <section className="lines">
            <SectionHeading heading="Lines" />
            <TimesheetLineForm
                show={showCreateLineForm}
                setShow={setShowCreateLineForm}
                timesheetLine={{
                    DocumentNo: timesheet.No,
                    Hours: 0,
                    LineNo: 0,
                    Project: '',
                    ProjectDescription: '',
                    TimesheetDate: '',
                }}
            />
            {(timesheet.Status === 'Created' || timesheet.Status === 'Rejected') &&
                <Button variant="success" onClick={() => { setShowCreateLineForm(true) }} style={{ marginBottom: 10 }}>
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;
                    Add
                </Button>
            }

            <section className="lines">
                {renderLines()}
            </section>
        </section>
    )
}



/**
 * 
 * @param {{timesheet: Timesheet, timesheetLine: TimesheetLine}} params0
 */
function TimesheetLineItem({ timesheet, timesheetLine }) {
    const [showEditLineForm, setShowEditLineForm] = useState(false)

    return (
        <div className="line" key={timesheetLine.LineNo}>
            <TimesheetLineForm
                show={showEditLineForm}
                setShow={setShowEditLineForm}
                timesheetLine={timesheetLine}
            />
            <div className="list-item-content">
                <div>{timesheetLine.TimesheetDate}</div>
                <div>{timesheetLine.Project} - {timesheetLine.ProjectDescription}</div>
                <div>{timesheetLine.Hours} hours</div>
            </div>
            <div className="list-item-actions">
                {(timesheet.Status === 'Created' || timesheet.Status === 'Rejected') &&
                    <Button className="edit-icon" variant="" onClick={() => setShowEditLineForm(true)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                }
            </div>
        </div>
    )
}