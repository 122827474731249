import React from "react";
import { Badge } from "react-bootstrap";

/**
 * @param {{ leaveRequest: LeaveRequest }} param0 
 * @returns 
 */
export function LeaveRequestStatusBadge({ leaveRequest }) {
    return (
        <Badge bg={badgeColorFor(leaveRequest?.Status)}>
            {leaveRequest?.Status}
        </Badge>
    )
}

/**
 * @param {{ timesheet: Timesheet }} param0 
 * @returns 
 */
export function TimesheetStatusBadge({ timesheet }) {
    return (
        <Badge bg={badgeColorFor(timesheet?.Status)}>
            {timesheet?.Status}
        </Badge>
    )
}
/**
 * 
 * @param {string} status 
 */
export function badgeColorFor(status) {
    switch (status) {
        case 'Approved': return 'success';
        case 'Rejected': return 'danger'
        default: return 'secondary'
    }
}