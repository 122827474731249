import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Stack } from "react-bootstrap";
import './css/RequestList.scss'
import { useSelector } from "react-redux";
import { selectLeaveRequests } from "../selectors/selectLMSData";
import { CreateLeaveRequestForm } from "./CreateLeaveRequestForm";
import { LeaveRequestDialog } from "./LeaveRequestDialog";
import { LeaveRequestStatusBadge } from "./Badges";

export const LeaveRequestList = () => {
    // const 
    /** @type {LeaveRequest[]} */
    const leaveRequests = useSelector(selectLeaveRequests());
    const [showCreateLeaveRequestForm, setShowCreateLeaveRequestForm] = useState(false)


    function renderLeaveRequests() {
        return leaveRequests.map(lr => {
            return (
                <LeaveRequestListItem leaveRequest={lr}
                    key={lr.DocumentNo} />
            )
        })
    }
    return (
        <div>
            <h1>Leave Requests</h1>
            <CreateLeaveRequestForm setShow={setShowCreateLeaveRequestForm} show={showCreateLeaveRequestForm} />
            <section className='actions my-2'>
                <Stack gap={2} direction='horizontal'>
                    <Button variant="success" onClick={() => setShowCreateLeaveRequestForm(true)}>
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        New
                    </Button>
                </Stack>
            </section>

            <div className="request-list">
                {renderLeaveRequests()}
            </div>
        </div>

    )
}


/**
 * 
 * @param {{leaveRequest: LeaveRequest }} props 
 */
const LeaveRequestListItem = props => {
    const { leaveRequest } = props
    const [showLeaveRequestDialog, setShowLeaveRequestDialog] = useState(false)

    return (
        <div>
            <LeaveRequestDialog
                setShow={setShowLeaveRequestDialog}
                show={showLeaveRequestDialog}
                leaveRequest={leaveRequest} />

            <Card style={{ marginTop: 10 }} className="request-list-item">
                <div className="list-item-content">
                    <div>{leaveRequest.LeaveType} LEAVE <LeaveRequestStatusBadge leaveRequest={leaveRequest} /></div>
                    <div>{leaveRequest.StartDate} to {leaveRequest.EndDate} ({leaveRequestDays(leaveRequest)})</div>
                </div>
                <div className="list-item-actions">
                    <Button className="edit-icon" variant="" onClick={() => setShowLeaveRequestDialog(true)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </div>
            </Card>
        </div>
    )
}


/**
 * 
 * @param { LeaveRequest } leaveRequest 
 */
export function leaveRequestDays(leaveRequest) {
    if (leaveRequest.Days === 1) {
        return '1 day'
    }
    return `${leaveRequest.Days} days`
}
