import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    /** @type {LMSData} */
    lmsData: null
}

export const lmsDataSlice = createSlice({
    name: 'lmsData',
    initialState,
    reducers: {
        lmsDataSaved(state, action) {
            /** @type {{lmsData: LMSData}} */
            const { lmsData } = action.payload
            state.lmsData = lmsData
        },
    }
})

export const {
    lmsDataSaved,
} = lmsDataSlice.actions

export default lmsDataSlice.reducer
