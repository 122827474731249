import { createSlice } from '@reduxjs/toolkit'

/**
 * @type {MessageInitialState}
 */
const initialState = {
  message: { type: 'info', content: '' }
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    messageCleared (state, _action) {
      state.message = { type: 'info', content: '' }
    },

    errorMessage (state, action) {
      state.message = {
        type: 'error',
        content: action.payload.content
      }
    },

    infoMessage (state, action) {
      state.message = {
        type: 'info',
        content: action.payload.content
      }
    }
  }
})

export const {
  errorMessage,
  infoMessage,
  messageCleared
} = messageSlice.actions

export default messageSlice.reducer
