import { combineReducers, configureStore } from '@reduxjs/toolkit'
import lmsDataReducer from './slices/lmsDataSlice'

export const reducer = combineReducers({
  lmsData: lmsDataReducer
})

export const store = configureStore({
  reducer
})
