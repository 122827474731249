import axios from 'axios'
import { urlFor } from '../../utils'
import { jwtDecode } from 'jwt-decode'


/**
 *
 * @param {LoginCredentials} credentials
 */
export const login = async credentials => {
    const loginResponse = await axios({
        method: 'post',
        url: urlFor('/auth/corpsuser/login'),
        data: { email: credentials.email?.toLowerCase() }
    })
    const authToken = loginResponse.data
    console.log(authToken)
    localStorage.setItem('authToken', authToken)
    window.location.href = '/'
}



class Auth {
    /**
       * @returns {EmployeeCard}
       */
    get appuser() {
        try {
            return parseJwt(this.authToken)
        } catch (e) {
            return null
        }
    }


    // get isTokenExpired() {
    //     return this.appuser && this.appuser?.exp * 1000 < new Date().getTime()
    // }



    get isLoggedIn() {
        const result =  !!this.email
        console.log('isLoggedIn is', result)
        return result;
    }

    get email() {
        return this.appuser?.Company_E_Mail
    }

    get authToken() {
        return this.getLocalStorageItem('authToken')
    }

    get fullName() {
        return `${this.appuser?.Last_Name} ${this.appuser?.First_Name} ${this.appuser?.Middle_Name}`.replace('  ', ' ').trim().toUpperCase()
    }



    getLocalStorageItem(itemName) {
        const value = localStorage.getItem(itemName)
        if (value && value != 'undefined' && value != 'null') { return value }
        return ''
    }
}
const parseJwt = (token) => {
    try {
        return jwtDecode(token)
    } catch (e) {
        console.log('parsing Jwt failed', e.message)
        return null
    }
}

const auth = new Auth()
export default auth
