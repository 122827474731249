import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button, Form, Modal, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faCheck, faPrint } from '@fortawesome/free-solid-svg-icons'
import { poster } from '../thunks/thunk-utils'
import { fetchLMSData } from '../thunks/lms-data/fetch-lms-data'
import { downloadLeaveRequestReport, showError, swalFire } from './utils'
import { LeaveRequestStatusBadge, badgeColorFor } from './Badges'

/**
 * @param {ApprovalRequestDialogProps} props
 * @returns
 */
export function ApprovalRequestDialog(props) {
    const { show, setShow, approvalRequest } = props
    const dispatch = useDispatch()
    const handleClose = () => setShow(false)
    const [isApproving, setIsApproving] = useState(false)
    const [showRejectionDialog, setShowRejectionDialog] = useState(false)

    async function handleApprove() {
        await actionHandler(setIsApproving, async () => {
            const resp = await poster('/approval-requests/approve', {
                requestId: approvalRequest?.DocumentNo
            })
            await swalFire(resp, 'Successfully approved approval request')
        })
    }
    async function handleReject() {
        setShowRejectionDialog(true)
    }
    async function handlePrint() {
        const documentNo = approvalRequest?.DocumentNo
        if (documentNo) {
            downloadLeaveRequestReport(documentNo)
        }
    }

    /**
     * @param {(b: boolean)=>void} inProgressSetter
     * @param {()=>Promise<void>} fn 
     */
    async function actionHandler(inProgressSetter, fn) {
        try {
            inProgressSetter(true)
            await fn()
            await dispatch(fetchLMSData())
            handleClose()
        }
        catch (e) {
            console.error(e)
        }
        finally {
            inProgressSetter(false)
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <RejectionDialog show={showRejectionDialog} setShow={setShowRejectionDialog} approvalRequest={approvalRequest} />

            <Modal.Header closeButton>
                <Modal.Title>Approval Request - {approvalRequest.DocumentNo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Badge bg={badgeColorFor(approvalRequest?.Status)}>
                        Leave Request by {approvalRequest?.EmployeeName}
                    </Badge>
                </div>
                <LeaveRequestStatusBadge leaveRequest={approvalRequest} />

                <LeaveDetails leaveRequest={approvalRequest} />
                <ApprovalEntries approvalRequest={approvalRequest} />
                <ApprovalComment approvalRequest={approvalRequest} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handlePrint} className='outline'>
                    <FontAwesomeIcon icon={faPrint} />
                    &nbsp;
                    Print
                </Button>
                <Button variant="secondary" onClick={handleReject} className='outline'>
                    <FontAwesomeIcon icon={faCancel} />
                    &nbsp;
                    Reject
                </Button >
                <Button variant="success" onClick={handleApprove} disabled={isApproving}>
                    <FontAwesomeIcon icon={faCheck} />
                    &nbsp;
                    {isApproving ? 'Approving' : 'Approve'}
                </Button >
            </Modal.Footer>
        </Modal>
    )
}

/**
 * @param {ApprovalRequestDialogProps} props
 * @returns
 */
export function RejectionDialog(props) {
    const { show, setShow, approvalRequest } = props
    const dispatch = useDispatch()
    const handleClose = () => {
        setForm({ Comment: '' })
        setShow(false)
    }
    const [isRejecting, setIsRejecting] = useState(false)

    const [form, setForm] = useState({
        Comment: '',
    })
    const handleFormChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }


    async function handleReject() {
        await actionHandler(setIsRejecting, async () => {
            if (!form.Comment) {
                await showError('Please provide rejection comment')
                return;
            }
            const resp = await poster('/approval-requests/reject', {
                requestId: approvalRequest.DocumentNo,
                comment: form.Comment
            })
            await swalFire(resp, 'Successfully rejected approval request')
        })
    }

    /**
    * @param {(b: boolean)=>void} inProgressSetter
    * @param {()=>Promise<void>} fn 
    */
    async function actionHandler(inProgressSetter, fn) {
        try {
            inProgressSetter(true)
            await fn()
            await dispatch(fetchLMSData())
            handleClose()
        }
        catch (e) {
            console.error(e)
        }
        finally {
            inProgressSetter(false)
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Rejecting Approval Request - {approvalRequest.DocumentNo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Rejection Comment</Form.Label>
                    <Form.Control type='text' name="Comment" onChange={handleFormChange} value={form.Comment} autoFocus={true} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleReject} disabled={isRejecting} className='outline'>
                    <FontAwesomeIcon icon={faCancel} />
                    &nbsp;
                    {isRejecting ? 'Rejecting' : 'Reject'}
                </Button >
            </Modal.Footer>
        </Modal>
    )
}

/**
 * 
 * @param {{ leaveRequest: LeaveRequest}} param0 
 */
export function LeaveDetails({ leaveRequest }) {
    if (!leaveRequest) return null;

    return (
        <section>
            <Table hover>
                <thead>
                    <tr>
                        <th>Leave Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Days</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{leaveRequest.LeaveType}</td>
                        <td>{leaveRequest.StartDate}</td>
                        <td>{leaveRequest.EndDate}</td>
                        <td>{leaveRequest.Days}</td>
                    </tr>
                </tbody>
            </Table>
        </section>
    )
}

/**
 * 
 * @param {{ approvalRequest: LeaveRequest}} param0 
 */
export function ApprovalEntries({ approvalRequest }) {
    if (!approvalRequest) return null;

    function renderTableRows() {
        return approvalRequest?.approvalEntries?.map(approvalEntry => {
            return (
                <tr key={approvalEntry.EntryId}>
                    <td>
                        {approvalEntry.Status === 'Approved' &&
                            <FontAwesomeIcon icon={faCheck} />
                        }
                        {approvalEntry.Status !== 'Approved' &&
                            <FontAwesomeIcon icon={faCancel} />
                        }
                    </td>
                    <td>{approvalEntry.ApproverLevel}</td>
                    <td>{approvalEntry.Status}</td>
                    <td>{approvalEntry.ApproverName}</td>
                </tr>
            )
        })
    }
    return (
        <div>
            <p>APPROVAL ENTRIES</p>
            <Table hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Level</th>
                        <th>Status</th>
                        <th>Approver Name</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </Table>
        </div>
    )
}

/**
 * 
 * @param {{ approvalRequest: LeaveRequest}} param0 
 */
export function ApprovalComment({ approvalRequest }) {
    if (!approvalRequest) return null;

    function renderTableRows() {
        return approvalRequest?.approvalEntries
            ?.filter(entry => entry.Comment)
            ?.map(approvalEntry => {
                return (
                    <tr key={approvalEntry.EntryId}>
                        <td>{approvalEntry.Comment}</td>
                    </tr>
                )
            })
    }
    return (
        <div>
            <p>COMMENTS</p>
            <Table hover>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </Table>
        </div>
    )
}

