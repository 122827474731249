export function selectLMSData() {
    /**
       * @param {import("../store-def").RootState} state
       * @returns {LMSData}
       */
    return state => {
        return state.lmsData.lmsData
    }
}

export function selectApprovalRequests() {
    /**
     * @param {import("../store-def").RootState} state
     * @returns {ApprovalEntry[]}
     */
    return state => {
        return selectLMSData()(state)?.approvalRequests ?? []
    }
}


export function selectLeaveRequests() {
    /**
   * @param {import("../store-def").RootState} state
   * @returns {LeaveRequest[]}
   */
    return state => {
        return selectLMSData()(state)?.leaveRequests ?? []
    }
}

export function selectLeaveTypes() {
    /**
   * @param {import("../store-def").RootState} state
   * @returns {LeaveType[]}
   */
    return state => {
        return selectLMSData()(state)?.leaveTypes ?? []
    }
}

export function selectIsStillLoadingLMSData() {
    /**
   * @param {import("../store-def").RootState} state
   * @returns {boolean}
   */
    return state => {
        return selectLMSData()(state) === null
    }
}


export function selectTimesheets() {
    /**
   * @param {import("../store-def").RootState} state
   * @returns {Timesheet[]}
   */
    return state => {
        return selectLMSData()(state)?.timesheets ?? []
    }
}

export function selectTimesheet(timesheetNo) {
    /**
   * @param {import("../store-def").RootState} state
   * @returns {Timesheet}
   */
    return state => {
        let ts = selectTimesheets()(state)?.find(ts => ts.No === timesheetNo)
        if(!ts) {
            // look for it from approval entries 
            ts = selectApprovalRequest(timesheetNo)(state)?.timesheet
        }
        return ts 
    }
}

/**
 * @param {string} requestId 
 * @returns 
 */
export function selectApprovalRequest(requestId) {
     /**
   * @param {import("../store-def").RootState} state
   * @returns {ApprovalEntry}
   */
     return state => {
        return selectApprovalRequests()(state)?.find(req => req.RequestId === requestId)
    }
}

export function selectProjects(timesheetNo) {
    /**
   * @param {import("../store-def").RootState} state
   * @returns {Project[]}
   */
    return state => {
        return selectLMSData()(state)?.projects ?? []
    }
}