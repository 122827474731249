import React, { useState } from 'react'
// import './css/List.scss'
// import AddIcon from '@mui/icons-material/Add';
// import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router'

import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDeleteLeft, faPaperPlane, faRemove, faSave } from '@fortawesome/free-solid-svg-icons'
import { deleter, poster } from '../thunks/thunk-utils'
import { fetchLMSData } from '../thunks/lms-data/fetch-lms-data'
import { selectLeaveTypes, selectProjects } from '../selectors/selectLMSData'
import _ from 'lodash'
import { errorMessageFor, showError, swalFire } from './utils'

/**
 * @param {EditTimesheetLineDialogProps} props
 * @returns
 */
export function TimesheetLineForm(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const show = props.show
    const setShow = props.setShow
    const handleClose = () => setShow(false)
    const [form, setForm] = useState(_.clone(props.timesheetLine))
    const [isCreating, setIsCreating] = useState(false)
    const [showDeleteLine, setShowDeleteLine] = useState(false)

    let actionMode = 'Create'
    if(props.timesheetLine.LineNo) { 
        actionMode = 'Edit';
    }
    async function handleSubmit() {
        try {
            setIsCreating(true)
            await poster('/timesheets/edit-timesheet-line', form)
            await dispatch(fetchLMSData())
            handleClose()
        }
        catch (e) {
            console.error(e)            
            showError(errorMessageFor(e))
        }
        finally {
            setIsCreating(false)
        }
    }
    async function handleDelete() {
        setShowDeleteLine(true)  
    }

    const handleFormChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <DeleteTimesheetLineDialog show={showDeleteLine} setShow={setShowDeleteLine} timesheetLine={props.timesheetLine} />
            <Modal.Header closeButton>
                <Modal.Title>{actionMode} Timesheet Line</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Timesheet Date</Form.Label>
                        <Form.Control type='date' name="TimesheetDate" onChange={handleFormChange} value={form.TimesheetDate} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Project</Form.Label>
                        <Form.Select name="Project" onChange={handleFormChange} value={form.Project}>
                            <ProjectOptions />
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Hours</Form.Label>
                        <Form.Control type='number' name="Hours" max={8} min={0} onChange={handleFormChange} value={form.Hours} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleDelete} disabled={isCreating}>
                    <FontAwesomeIcon icon={faRemove} />
                    &nbsp;
                    DELETE
                </Button>
                <Button variant="success" onClick={handleSubmit} disabled={isCreating}>
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp;
                    {isCreating ? 'SAVING' : 'SAVE'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


/**
 * @param {EditTimesheetLineDialogProps} props
 * @returns
 */
export function DeleteTimesheetLineDialog(props) {
    const { show, setShow, timesheetLine } = props
    const dispatch = useDispatch()
    const handleClose = () => {
        setShow(false)
    }
    const [isDeleting, setIsDeleting] = useState(false)

    async function handleDelete() {
        try {
            setIsDeleting(true)
            const resp = await deleter('/timesheets/delete-timesheet-line', timesheetLine)
            swalFire(resp, 'Successfully deleted timesheet line')
            await dispatch(fetchLMSData())
            handleClose()
        }
        catch (e) {
            console.error(e)
            showError(errorMessageFor(e))
        }
        finally {
            setIsDeleting(false)
        }
    }


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Timesheet Line</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete the timesheet line?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete} disabled={isDeleting} className='outline'>
                    <FontAwesomeIcon icon={faRemove} />
                    &nbsp;
                    {isDeleting ? 'Deleting' : 'Delete'}
                </Button >
            </Modal.Footer>
        </Modal>
    )
}




const ProjectOptions = () => {
    const projects = useSelector(selectProjects())
    return (
        <>
            <option value=""></option>
            {projects.map(project => {
                return (
                    <option
                        value={project.Code}
                        key={project.Code}
                    >
                        {project.Code} - {project.Description}
                    </option>
                )
            }
            )
            }
        </>
    )
}

